import React from 'react';
import { Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import cc from 'classcat';
import { IconPhone, IconEmail, IconLinkedIn } from '~/components/icons';
import './footer.scss';

const getPath = url => url.replace('https://pandr.du.works', '');

const Footer = () => {
  const {
    acf: { options },
    wp: { menuItems },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          menuItems(where: { location: FOOTER }) {
            nodes {
              id
              label
              target
              url
              cssClasses
            }
          }
        }
        acf: wordpressAcfOptions {
          options {
            logo {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 320) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            phone
            email
            officeAddress
            mailingAddress
          }
        }
      }
    `
  );

  const logo = options.logo?.localFile?.childImageSharp.fluid;

  return (
    <>
      <footer className="site-footer">
        <div className="container">
          <div className="footer-inner">
            <div className="footer-logo logo">
              <Img fluid={logo} fadeIn={false} />
            </div>

            <nav className="footer-menu">
              <ul className="menu">
                {menuItems.nodes?.map(item => (
                  <li
                    key={item.id}
                    className={cc(['menu-item', item.cssClasses])}
                  >
                    <Link
                      to={getPath(item.url)}
                      target={item.target}
                      rel={
                        item.target === '_blank' ? 'noopener noreferrer' : null
                      }
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="footer-contact">
              <div className="footer-address primary">
                <span className="label">Office Address:</span>
                <address>{options.officeAddress}</address>
                <a href={`tel:${options.phone}`} className="contact-link">
                  <IconPhone />
                  {options.phone}
                </a>
              </div>
              <div className="footer-address secondary">
                <span className="label">Mailing Address:</span>
                <address>{options.mailingAddress}</address>
                <a href={`mailto:${options.email}`} className="contact-link">
                  <IconEmail />
                  {options.email}
                </a>
              </div>
            </div>
          </div>

          <div className="footer-social">
            <a
              className="icon-button"
              href="//www.linkedin.com/company/prplumbing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLinkedIn />
            </a>
            <span>Connect</span>
          </div>

          <div className="footer-legal">
            <span>©{new Date().getFullYear()} P&R Plumbing & Heating Inc.</span>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
