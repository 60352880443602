import React, { useState, useEffect, createRef } from 'react';
import { useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import cc from 'classcat';
import { IconHamburger, IconClose, IconPhone } from '~/components/icons';
import './header.scss';

const getPath = url => url.replace('https://pandr.du.works', '');

const Header = ({ location }) => {
  const {
    acf: { options },
    wp: { menuItems },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          menuItems(where: { location: PRIMARY }) {
            nodes {
              id
              label
              target
              url
              cssClasses
            }
          }
        }
        acf: wordpressAcfOptions {
          options {
            logo {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 320) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            phone
          }
        }
      }
    `
  );

  const logo = options.logo?.localFile?.childImageSharp.fluid;
  const menuRef = createRef();
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  useEffect(() => {
    const el = menuRef.current;

    if (isActive) {
      el.classList.add('is-active');
      el.animate([{ opacity: 0 }, { opacity: 1 }], 250);
    } else {
      const closeAnimation = el.animate([{ opacity: 1 }, { opacity: 0 }], 250);
      closeAnimation.onfinish = () => {
        el.classList.remove('is-active');
      };
    }

    document.body.classList.toggle('locked', isActive);
    return () => document.body.classList.remove('locked');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const toggleMenu = () => {
    setActive(!isActive);
  };

  return (
    <>
      <header className="site-header">
        <div className="container">
          <div className="header-inner">
            <Link to="/" className="header-logo logo">
              <Img fluid={logo} fadeIn={false} />
            </Link>
            <button className="header-menu-toggle" onClick={toggleMenu}>
              {isActive ? <IconClose /> : <IconHamburger />}
            </button>
            <nav ref={menuRef} className="header-menu">
              <Link to="/" className="header-menu-mobile-logo logo">
                <Img fluid={logo} fadeIn={false} />
              </Link>
              <a href={`tel:${options.phone}`} className="header-phone">
                <i>
                  <IconPhone />
                </i>
                {options.phone}
              </a>
              <ul className="menu">
                {menuItems.nodes?.map(item => (
                  <li
                    key={item.id}
                    className={cc(['menu-item', item.cssClasses])}
                  >
                    <Link to={getPath(item.url)} target={item.target}>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
