import React from 'react';

import { TransitionGroup, Transition } from 'react-transition-group';

import Header from './header';
import Footer from './footer';

import './layout.scss';

const timeout = 250;
const ease = 'ease';
const styles = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
    transition: `opacity ${timeout}ms ${ease}`,
  },
  exiting: {
    opacity: 1,
    transition: `opacity ${timeout}ms ${ease}`,
    position: 'absolute',
    zIndex: -1,
  },
};

const Layout = ({ children, location }) => {
  return (
    <>
      <Header location={location} />

      <TransitionGroup component="main" className={'site-main'}>
        <Transition
          key={location.pathname}
          timeout={{
            enter: 0,
            exit: timeout,
          }}
        >
          {status => (
            <div className="site-wrapper" style={styles[status]}>
              <div className="site-content">{children}</div>
              <Footer />
            </div>
          )}
        </Transition>
      </TransitionGroup>
    </>
  );
};

export default Layout;
